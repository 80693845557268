import React from "react";

export function HiddenColumns({
  columnsHidden,
  header,
  setColumnsHidden,
}: {
  columnsHidden: number[];
  header: any;
  setColumnsHidden: any;
}) {
  function showColumn(name: string) {
    const index = header.indexOf(name);
    setColumnsHidden(columnsHidden.filter((i: any) => i !== index));
  }
  return (
    <>
      <div>
        <div className="w-fit text-black mx-auto py-1 border-b-[1px] border-[#bebbb8] bg-blue-100">
          <p className="text-start  mx-2 w-[100px] block cursor-pointer">Hidden</p>
        </div>

        {/* hidden columns */}
        {columnsHidden.length > 0
          ? columnsHidden.map((item: any, index: number) => {
              return (
                <div
                key={index}
                onClick={() => showColumn(header[item])}
                className="w-auto inline-block px-4 py-2 mx-2 text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:from-blue-600 hover:via-blue-700 hover:to-blue-800 rounded-lg shadow-md hover:shadow-lg transition duration-200 ease-in-out cursor-pointer border-b border-[#bebbb8] text-center"
              >
                {header[item].length > 10 ? header[item].slice(0, 10) + "..." : header[item]}
              </div>
              );
            })
          : null}
      </div>
    </>
  );
}
