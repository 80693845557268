export const ADS_API_URL = "https://api.adwatcher.tech/"
// export const ADS_API_URL = "http://localhost:80/"


export const API_URL = '/api';
// export const API_URL = 'http://localhost:8000/api';




export const PRICE  = 2
export const RATING  =12
export const TOTAL_REVIEW  = 15